const { eventKundefilialeGesetzt } = require("../../common/customEvents");
const { appendHtml } = require("../../common/helper");
const { callbackFollowFavoriteStore } = require("../../common/appCallback");

class KundenfilialeButton extends HTMLElement {
  connectedCallback() {
    this.declareVariables();
    this.initListener();
  }

  declareVariables() {
    this.container = this.querySelector(".container");
    this.isFilialauswahl = this.closest("filiale-auswahl") !== null;
    this.filialenContainer = this.closest(".filialen");
  }

  initListener() {
    this.container.addEventListener("click", (event) => {
      const target = event.target;
      const button = target.closest("[interaction=filiale-setzen]");
      if (button) {
        const filialId = button.getAttribute("filial-id");
        this.setFiliale(filialId);
      }
    });
  }

  async setFiliale(filialId) {
    const fetchResponse = await this.fetchButton(filialId, "POST");
    if (fetchResponse.status === 200) {
      if (this.filialenContainer !== null) {
        this.resetOldFiliale();
      }
      const fetchedHTML = await fetchResponse.text();
      this.updateDOM(this.container, fetchedHTML);
      callbackFollowFavoriteStore(this, filialId);
      eventKundefilialeGesetzt();
    }
  }

  async fetchButton(filialId, method) {
    try {
      const config = {
        method: method,
        credentials: "include",
        mode: "same-origin",
        headers: {
          "Accept": "text/html",
        }
      }
      let url;
      if ( this.isFilialauswahl ) {
        url = `/include/kundenfiliale/button/${filialId}?filialauswahlDialog=true&headless=true`;
      } else {
        url = `/include/kundenfiliale/button/${filialId}?headless=true`;
      }
      const response = await fetch(url, config);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async resetOldFiliale() {
    const containerOldFiliale = this.filialenContainer.querySelector("button[data-state=marked]").closest(".container");
    const filialId = this.filialenContainer.querySelector("button[data-state=marked]").getAttribute("filial-id");

    const fetchResponse = await this.fetchButton(filialId, "GET");
    if (fetchResponse.status === 200) {
      const fetchedHTML = await fetchResponse.text();
      this.updateDOM(containerOldFiliale, fetchedHTML);
    }
  }

  updateDOM(container, fetchedHTML) {
    container.innerHTML = "";
    appendHtml(container, fetchedHTML);
  }

}

if (!customElements.get("kundenfiliale-button")) {
  customElements.define("kundenfiliale-button", KundenfilialeButton);
}
