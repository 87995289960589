export { appendHtml, replaceHtml, templateToDOMElement };

function appendHtml(container, html) {
  const range = document.createRange();
  range.setStart(container, 0);
  range.setEnd(container, 0);
  container.appendChild(range.createContextualFragment(html));
}

function replaceHtml(container, html) {
  container.innerHTML = "";
  appendHtml(container, html);
}

function templateToDOMElement(html) {
  const template = document.createElement('template');
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
}